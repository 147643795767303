
import Vue from 'vue'
import ProgressSpinner from 'primevue/progressspinner'

export default Vue.extend({
  name: 'SubsystemsDropdown',
  components: {
    ProgressSpinner,
  },
  props: {
    api: {
      type: Object as () => any,
      required: true,
    },
  },
  data() {
    return {
      subsystemsNav: [] as ISubsystemEntity[],
      isPageLocked: false,
    }
  },
  async mounted() {
    if (!this.subsystemsNav.length) {
      try {
        const { results } = await this.api.getEntitiesList({
          limiter: { type: 'PlatformIntegration' },
        })
        this.subsystemsNav = results.map((el: any) => ({
          ...el,
          label: el.title,
          icon: el.icon.iconClass,
          command: async (e: IMenuBarEvent<ISubsystemEntity>) => {
            e.originalEvent.preventDefault()
            try {
              this.isPageLocked = true
              try {
                const headers = await this.api.getHeaders()
                /* eslint-disable camelcase */
                const { access_token } = await fetch('/gateway/auth/accessToken', {
                  headers,
                }).then((res) => res.json())
                /* eslint-disable camelcase */
                window.open(decodeURIComponent(`${el.url}?access_token=${access_token}`))
              } catch (error) {
                console.error(error)
              }
            } catch (error) {
              console.error(error)
            } finally {
              this.isPageLocked = false
            }
          },
        }))
      } catch (error) {
        console.error(error)
      }
    }
  },
})
