(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("primevue/splitbutton"), require("primevue/tooltip"), require("single-spa-vue"), require("vue-router"), require("primevue/toastservice"), require("primevue/message"), require("vue-i18n"), require("primevue/progressspinner"), require("vue"), require("@netvision/lib-api-repo"), require("primevue/selectbutton"), require("primevue/toast"), require("primevue/menubar"), require("primevue/button"));
	else if(typeof define === 'function' && define.amd)
		define(["primevue/splitbutton", "primevue/tooltip", "single-spa-vue", "vue-router", "primevue/toastservice", "primevue/message", "vue-i18n", "primevue/progressspinner", "vue", "@netvision/lib-api-repo", "primevue/selectbutton", "primevue/toast", "primevue/menubar", "primevue/button"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("primevue/splitbutton"), require("primevue/tooltip"), require("single-spa-vue"), require("vue-router"), require("primevue/toastservice"), require("primevue/message"), require("vue-i18n"), require("primevue/progressspinner"), require("vue"), require("@netvision/lib-api-repo"), require("primevue/selectbutton"), require("primevue/toast"), require("primevue/menubar"), require("primevue/button")) : factory(root["primevue/splitbutton"], root["primevue/tooltip"], root["single-spa-vue"], root["vue-router"], root["primevue/toastservice"], root["primevue/message"], root["vue-i18n"], root["primevue/progressspinner"], root["vue"], root["@netvision/lib-api-repo"], root["primevue/selectbutton"], root["primevue/toast"], root["primevue/menubar"], root["primevue/button"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__3261__, __WEBPACK_EXTERNAL_MODULE__3b23__, __WEBPACK_EXTERNAL_MODULE__4171__, __WEBPACK_EXTERNAL_MODULE__6389__, __WEBPACK_EXTERNAL_MODULE__68f9__, __WEBPACK_EXTERNAL_MODULE__6e08__, __WEBPACK_EXTERNAL_MODULE__85b3__, __WEBPACK_EXTERNAL_MODULE__8aba__, __WEBPACK_EXTERNAL_MODULE__8bbf__, __WEBPACK_EXTERNAL_MODULE__9463__, __WEBPACK_EXTERNAL_MODULE_a28b__, __WEBPACK_EXTERNAL_MODULE_d26e__, __WEBPACK_EXTERNAL_MODULE_d976__, __WEBPACK_EXTERNAL_MODULE_f095__) {
return 