import './set-public-path'
import Vue from 'vue'
import singleSpaVue from 'single-spa-vue'
import VueRouter from 'vue-router'
import VueI18n from 'vue-i18n'
import { RUSSIAN_TRANSLATIONS } from './translations/ru'
import Button from 'primevue/button'
import SelectButton from 'primevue/selectbutton'
import SplitButton from 'primevue/splitbutton'
import Message from 'primevue/message'
import Tooltip from 'primevue/tooltip'
import Toast from 'primevue/toast'
import ToastService from 'primevue/toastservice'
import Menubar from 'primevue/menubar'
// @ts-ignore
import VueTeleport from '@desislavsd/vue-teleport'
import App from './App.vue'

Vue.directive('tooltip', Tooltip)
Vue.component('Button', Button)
Vue.component('SelectButton', SelectButton)
Vue.component('SplitButton', SplitButton)
Vue.component('Message', Message)
Vue.component('Toast', Toast)
Vue.component('Menubar', Menubar)
Vue.use(ToastService)
Vue.use(VueTeleport)
Vue.use(VueRouter)
Vue.use(VueI18n)

Vue.prototype.$primevue = { ripple: true }
Vue.config.productionTip = false
const routes = [] as any
const router = new VueRouter({
  base: __dirname,
  mode: 'history',
  routes,
})

const locale = 'ru'
const TRANSLATIONS = {
  ru: RUSSIAN_TRANSLATIONS,
}
const i18n = new VueI18n({
  locale,
  messages: TRANSLATIONS,
})

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    router,
    i18n,
    render: (h: any) => h(App),
  },
})

export const bootstrap = vueLifecycles.bootstrap
export const mount = vueLifecycles.mount
export const unmount = vueLifecycles.unmount
export const update = vueLifecycles.update
